// extracted by mini-css-extract-plugin
export var root = "Job-module--root--2_pf5";
export var company = "Job-module--company--5ByTG";
export var dateRange = "Job-module--dateRange--3UG1S";
export var desc = "Job-module--desc--2bp3_";
export var projects = "Job-module--projects--1JSsH";
export var project = "Job-module--project--2uM8Z";
export var flexWrapper = "Job-module--flexWrapper--27w2k";
export var figure = "Job-module--figure--3zjbR";
export var skillSetsVis = "Job-module--skillSetsVis--2BreQ";
export var skills = "Job-module--skills--2sErC";